const TeamIcon = () => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.98 12H5.3C2.612 12 0.5 14.2 0.5 17V19C0.5 19.6 0.884 20 1.46 20C2.036 20 2.42 19.6 2.42 19V17C2.42 15.3 3.668 14 5.3 14H12.98C14.612 14 15.86 15.3 15.86 17V19C15.86 19.6 16.244 20 16.82 20C17.396 20 17.78 19.6 17.78 19V17C17.78 14.2 15.668 12 12.98 12ZM9.14 10C11.828 10 13.94 7.8 13.94 5C13.94 2.2 11.828 0 9.14 0C6.452 0 4.34 2.2 4.34 5C4.34 7.8 6.452 10 9.14 10ZM9.14 2C10.772 2 12.02 3.3 12.02 5C12.02 6.7 10.772 8 9.14 8C7.508 8 6.26 6.7 6.26 5C6.26 3.3 7.508 2 9.14 2ZM19.988 12.1C19.508 12 18.932 12.3 18.836 12.8C18.74 13.3 19.028 13.9 19.508 14C20.852 14.4 21.716 15.6 21.716 16.9V18.9C21.716 19.5 22.1 19.9 22.676 19.9C23.252 19.9 23.636 19.5 23.636 18.9V16.9C23.54 14.7 22.1 12.7 19.988 12.1ZM16.052 0.1C15.572 1.86265e-09 15.092 0.3 14.9 0.9C14.804 1.4 15.092 2 15.572 2.1C17.108 2.5 17.972 4.1 17.684 5.8C17.396 6.9 16.532 7.7 15.572 8C15.092 8.2 14.804 8.7 14.9 9.2C14.996 9.6 15.38 9.9 15.86 9.9C15.956 9.9 16.052 9.9 16.148 9.9C17.78 9.4 19.124 8 19.604 6.3C20.18 3.5 18.644 0.8 16.052 0.1Z"
        fill="#1A2026"
      />
    </svg>
  );
};

export default TeamIcon;
