import {datadogRum} from '@datadog/browser-rum';

export function initializeDatadog(config) {
  if (!config.applicationId) {
    return;
  }
  const updatedConfig = {
    ...config,
    trackInteractions: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
  };
  datadogRum.init(updatedConfig);
}
