import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {deconstructInvitationCode} from '../../utils/url-helpers';
import connectingImage from '../../assets/connecting.svg';
import celebrateImage from '../../assets/celebrate.svg';
import {TeamIcon} from '../../components/shared/icons';
import Button from '../../components/shared/button';

import './index.less';
/**
 * if not logged in it should kick  to login(and return here once logged in)
 * if logged in it should check and see if the invitation is valid and legitimate and addressed to the caller
 * if the invitation is not valid -> GOTO home
 * if the invitation is valid -> show the invitation details and prompt to accept
 * if accepted -> call server and switch user's team and log out
 * if you decline -> GOTO home, do nothing to the server - could click the link in email again sometime
 */
const SwitchTeamPage = () => {
  const navigate = useNavigate();
  const {invitationCode} = useParams();
  const {id: invitationId} = deconstructInvitationCode(invitationCode);
  const [loading, setLoading] = useState(true);
  const [complete, setComplete] = useState(false);
  const [teamName, setTeamName] = useState('');
  const acceptURI = `/team-switch/${invitationCode}`;
  useEffect(() => {
    const fetchInvitationData = async () => {
      try {
        const response = await fetch(`/invitation/team/${invitationId}`, {
          method: 'GET',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-Forwarded-Proto': 'https',
          },
        }).then((res) => res.json());
        setLoading(false);
        setTeamName(response.existingTeam.name);
      } catch (error) {
        // if we cannot get the invite, we cannot proceed, go to error state
        navigate('/failure-general');
      }
    };
    fetchInvitationData();
  }, [invitationId]);

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(acceptURI, {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'X-Forwarded-Proto': 'https',
          'csrf-token': window.csrfToken,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to switch team');
      }
      setComplete(true);
      // redirect to login page after 5 seconds
      setTimeout(() => {
        window.location = window.earthPortalUrl;
      }, 5000);
    } catch (error) {
      return navigate('/failure-general');
    }
  };

  if (loading) return;
  if (complete) {
    return (
      <div className="team-switch-form">
        <img
          src={celebrateImage}
          className="team-switch-form__image"
          alt="switch team image"
          aria-hidden="true"
        />
        <p className="team-switch-form__heading">
          You have successfully joined the team:
        </p>
        <div className="team-switch-form__team-name">
          <TeamIcon />
          <span className="bold">{teamName}</span>
        </div>
        <div className="team-switch-form__disclaimer-text">
          You must log in to access your new team. Redirecting to login page...
        </div>
        <div className="team-switch-form__btn-container">
          <Button
            className="team-switch-form__button"
            isPrimary={true}
            text="Login"
            name="login"
            value="login"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = window.earthPortalUrl;
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="team-switch-form">
      <img
        src={connectingImage}
        className="team-switch-form__image"
        alt="switch team image"
        aria-hidden="true"
      />
      <p className="team-switch-form__heading">
        You have been invited to join:
      </p>
      <div className="team-switch-form__team-name">
        <TeamIcon />
        <span className="bold">{teamName}</span>
      </div>
      <div className="team-switch-form__disclaimer-text">
        You will no longer have access to your current team's events and
        diagrams. However, you will still have access to events you have been
        added to as a collaborator. After switching teams, you will be logged
        out.
      </div>
      <form onSubmit={submitForm}>
        <div className="team-switch-form__btn-container">
          <Button
            className="team-switch-form__button"
            isPrimary={false}
            text="No"
            name="no"
            value="no"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = window.homeUrl;
            }}
          />
          <Button
            className="team-switch-form__button"
            isPrimary={true}
            text="Join new team"
            name="allow"
            value="allow"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
export default SwitchTeamPage;
