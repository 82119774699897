// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-switch-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.team-switch-form__team-name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
}
.team-switch-form__team-name svg {
  margin-right: 10px;
}
.team-switch-form__image {
  height: 400px;
  width: 400px;
}
.team-switch-form__heading {
  font-size: 16px;
  line-height: 125%;
  font-weight: 300;
}
.team-switch-form__disclaimer-text {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  max-width: 550px;
  text-align: center;
  line-height: 16px;
  color: #69717a;
}
.team-switch-form__btn-container {
  display: flex;
  justify-content: center;
  margin: 16px 0;
  column-gap: 16px;
}
.team-switch-form__button {
  min-width: 120px;
  min-height: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/routes/switch-team-page/index.less"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;AAGE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AADJ;AAJE;EAOI,kBAAA;AAAN;AAGE;EACE,aAAA;EACA,YAAA;AADJ;AAGE;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;AADJ;AAGE;EACE,eAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAGE;EACE,aAAA;EACA,uBAAA;EACA,cAAA;EACA,gBAAA;AADJ;AAGE;EACE,gBAAA;EACA,gBAAA;AADJ","sourcesContent":["@import '../../style/colors.less';\n@import '../../style/mobile.less';\n\n.team-switch-form {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  &__team-name {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 12px 0;\n    svg {\n      margin-right: 10px;\n    }\n  }\n  &__image {\n    height: 400px;\n    width: 400px;\n  }\n  &__heading {\n    font-size: 16px;\n    line-height: 125%;\n    font-weight: 300;\n  }\n  &__disclaimer-text {\n    margin-top: 8px;\n    margin-bottom: 8px;\n    font-size: 12px;\n    max-width: 550px;\n    text-align: center;\n    line-height: 16px;\n    color: @TextIconInteractive-soft;\n  }\n  &__btn-container {\n    display: flex;\n    justify-content: center;\n    margin: 16px 0;\n    column-gap: 16px;\n  }\n  &__button {\n    min-width: 120px;\n    min-height: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
