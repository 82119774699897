import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import Background from '../../components/background';
import Footer from '../../components/footer';
import Modal from '../../components/modals';
import {globalUIContext, useGlobalUI} from '../../utils/global-ui-helpers';
import {SOCIAL_TABLES_TITLE} from '../../constants/page-title';
import './index.less';

const EXCLUDE_FOOTER_PAGES = [
  '/success',
  '/billing-success',
  '/saml-error',
  '/oauth/authorize',
  '/oauth-authorize-error',
  '/failure-general',
  '/request-error',
];
const EXCLUDE_BG_PAGES = [
  '/success',
  '/billing-success',
  ...EXCLUDE_FOOTER_PAGES,
];

const Root = () => {
  const globalUI = useGlobalUI();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const displayBg =
    !EXCLUDE_BG_PAGES.includes(pathname) &&
    !(pathname.startsWith('/saml/') || pathname.startsWith('/switch-team/'));
  const displayFooter =
    !EXCLUDE_FOOTER_PAGES.includes(pathname) &&
    !(pathname.startsWith('/saml/') || pathname.startsWith('/switch-team/'));
  return (
    <globalUIContext.Provider value={globalUI}>
      <div
        id="screen-reader-announcer"
        role="region"
        className="screen-reader-only"
        aria-live="assertive"
        aria-atomic="true"
      >
        Navigated to {SOCIAL_TABLES_TITLE}
      </div>
      <header className="header">
        <img
          id="st-logo"
          src="../../assets/images/logo.svg"
          onClick={() => navigate('/')}
          alt="socialtables logo"
          aria-label="Go back to the main page"
          tabIndex={0}
        />
      </header>
      <main className="main">
        <Outlet />
      </main>
      {displayFooter && <Footer />}
      {globalUI?.modal?.modalName && <Modal />}
      {!globalUI.isMobile && displayBg ? <Background /> : null}
    </globalUIContext.Provider>
  );
};

export default Root;
