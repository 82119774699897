export const onLoginSuccess = (user) => {
  try {
    if (window.cventAnalytics && user) {
      const data = {
        platformUserId: user.platform_user_id,
        email: user.email,
        firstName: user.addressable_name,
        lastName: user.sortable_name,
        persona: 'planner',
        isCVIIUser: false,
      };
      window.cventAnalytics.identify(data);
    }
  } catch (error) {
    console.error(error);
  }
};
