import {useState, useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {globalUIContext} from '../../../utils/global-ui-helpers';
import CreditCard from './credit-card';
import FormHeader from '../../shared/form-header';
import Button from '../../shared/button';
import InputField from '../../shared/input-field';
import Dropdown from '../../shared/dropdown';
import {
  STATE_OPTIONS,
  EMAIL_FIELD,
  NAME_FIELD,
  CARD_FIELD,
  ADDRESS_LINE1_FIELD,
  ADDRESS_LINE2_FIELD,
  ADDRESS_COUNTRY_FIELD,
  ADDRESS_ZIP,
  ADDRESS_CITY,
  ADDRESS_STATE,
} from '../../../constants/billings';
import {
  PAYMENT_PROCESSING_MODAL,
  PAYMENT_ERROR_MODAL,
} from '../../../constants/modals';
import {BILLING_INFORMATION_TITLE} from '../../../constants/page-title';
import {
  ExternalLink,
  PRODUCT_TERMS_OF_USE_LINK,
} from '../../shared/external-link';
import {REQUIRED_FORM_FIELD_TEXT} from '../../../constants/errors';
import {validateZip} from '../../../utils/validate-helpers';
import {focusField} from '../../../utils/form-helpers';
import './index.less';

const BILLING_FORM_ORDER = [
  EMAIL_FIELD,
  ADDRESS_LINE1_FIELD,
  ADDRESS_LINE2_FIELD,
  ADDRESS_COUNTRY_FIELD,
  ADDRESS_ZIP,
  ADDRESS_CITY,
  ADDRESS_STATE,
  NAME_FIELD,
  CARD_FIELD,
];

const BillingForm = ({email, enableProgressIcon = true}) => {
  const navigate = useNavigate();
  const {openModal, closeModal} = useContext(globalUIContext);
  const stripe = useStripe();
  const elements = useElements();
  const [isDisabled, setIsDisabled] = useState(true);
  const [cardElement, setCardElement] = useState(null);
  const [formData, setFormData] = useState({
    [EMAIL_FIELD]: email,
    [NAME_FIELD]: '',
    [ADDRESS_LINE1_FIELD]: '',
    [ADDRESS_LINE2_FIELD]: '',
    [ADDRESS_COUNTRY_FIELD]: 'US',
    [ADDRESS_ZIP]: '',
    [ADDRESS_CITY]: '',
    [ADDRESS_STATE]: '',
  });
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (Object.keys(formErrors).length) {
      focusField(formErrors, BILLING_FORM_ORDER);
    }
    const shouldFocusOnCreditCardField =
      Object.keys(formErrors).length === 1 && formErrors[CARD_FIELD];
    if (shouldFocusOnCreditCardField && cardElement) {
      cardElement.focus();
    }
  }, [formErrors]);

  const isValidForm = () => {
    const errors = {};
    if (!formData[NAME_FIELD]) {
      errors[NAME_FIELD] = REQUIRED_FORM_FIELD_TEXT;
    }
    if (!formData[ADDRESS_LINE1_FIELD]) {
      errors[ADDRESS_LINE1_FIELD] = REQUIRED_FORM_FIELD_TEXT;
    }
    if (!formData[ADDRESS_ZIP]) {
      errors[ADDRESS_ZIP] = REQUIRED_FORM_FIELD_TEXT;
    } else if (!validateZip(formData[ADDRESS_ZIP])) {
      errors[ADDRESS_ZIP] = 'Valid Postal Code Required';
    }
    if (!formData[ADDRESS_CITY]) {
      errors[ADDRESS_CITY] = REQUIRED_FORM_FIELD_TEXT;
    }
    if (!formData[ADDRESS_STATE]) {
      errors[ADDRESS_STATE] = REQUIRED_FORM_FIELD_TEXT;
    }
    const cardElement = document.getElementById('stripe-card-element');
    if (cardElement?.classList?.contains('StripeElement--empty')) {
      errors[CARD_FIELD] = REQUIRED_FORM_FIELD_TEXT;
    } else if (formErrors[CARD_FIELD]) {
      errors[CARD_FIELD] = formErrors[CARD_FIELD];
    }
    setFormErrors(errors);
    return !Object.keys(errors).length;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    if (isValidForm()) {
      const cardElement = elements.getElement(CardElement);
      openModal(PAYMENT_PROCESSING_MODAL, {canClose: false});
      try {
        const {error, token} = await stripe.createToken(cardElement, formData);
        if (error) {
          throw error;
        }
        if (token) {
          const body = {
            email,
            token,
            _csrf: window.csrfToken || '',
          };
          const response = await fetch('/upgrade-plan', {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              'X-Forwarded-Proto': 'https',
            },
            body: JSON.stringify(body),
          }).then((res) => res);
          if (response.ok) {
            closeModal();
            navigate('/billing-success');
          } else {
            throw new Error(response.statusText);
          }
        }
      } catch (err) {
        openModal(PAYMENT_ERROR_MODAL);
      }
    }
  };

  return (
    <div className="billing-form-container">
      <FormHeader
        current={3}
        max={3}
        headerText={BILLING_INFORMATION_TITLE}
        enableProgressIcon={enableProgressIcon}
      />
      <form className="billing-form-container__form" onSubmit={handleSubmit}>
        <InputField
          inputFieldKey={EMAIL_FIELD}
          labelText="Email"
          value={email}
          ariaLabel="Your email"
          uneditable={true}
        />
        <InputField
          inputFieldKey={ADDRESS_LINE1_FIELD}
          labelText="Address Line 1"
          inputPlaceholder=""
          ariaLabel="Enter your address line 1"
          value={formData[ADDRESS_LINE1_FIELD]}
          onChange={(value) => {
            setFormData({...formData, [ADDRESS_LINE1_FIELD]: value});
            if (formErrors[ADDRESS_LINE1_FIELD]) {
              setFormErrors({...formErrors, [ADDRESS_LINE1_FIELD]: ''});
            }
          }}
          error={formErrors[ADDRESS_LINE1_FIELD]}
          isRequired
        />
        <InputField
          inputFieldKey={ADDRESS_LINE2_FIELD}
          labelText="Address Line 2"
          inputPlaceholder=""
          ariaLabel="Enter your address line 2"
          value={formData[ADDRESS_LINE2_FIELD]}
          onChange={(value) => {
            setFormData({...formData, [ADDRESS_LINE2_FIELD]: value});
            if (formErrors[ADDRESS_LINE2_FIELD]) {
              setFormErrors({...formErrors, [ADDRESS_LINE2_FIELD]: ''});
            }
          }}
        />
        <InputField
          inputFieldKey={ADDRESS_CITY}
          labelText="City"
          inputPlaceholder=""
          ariaLabel="Enter your address city"
          isRequired
          value={formData[ADDRESS_CITY]}
          onChange={(value) => {
            setFormData({...formData, [ADDRESS_CITY]: value});
            if (formErrors[ADDRESS_CITY]) {
              setFormErrors({...formErrors, [ADDRESS_CITY]: ''});
            }
          }}
          error={formErrors[ADDRESS_CITY]}
        />
        <div className="billing-form-container__form__section">
          <Dropdown
            inputFieldKey={ADDRESS_STATE}
            labelText="State"
            options={STATE_OPTIONS}
            placeholder="Select a state"
            isRequired
            error={formErrors[ADDRESS_STATE]}
            selectedOption={formData[ADDRESS_STATE]}
            onSelectionChange={(option) => {
              setFormData({...formData, [ADDRESS_STATE]: option});
              if (formErrors[ADDRESS_STATE]) {
                setFormErrors({...formErrors, [ADDRESS_STATE]: ''});
              }
            }}
          />
          <InputField
            inputFieldKey={ADDRESS_ZIP}
            labelText="Postal Code"
            inputPlaceholder=""
            ariaLabel="Enter your address postal code"
            isRequired
            value={formData[ADDRESS_ZIP]}
            onChange={(value) => {
              setFormData({...formData, [ADDRESS_ZIP]: value});
              if (formErrors[ADDRESS_ZIP]) {
                setFormErrors({...formErrors, [ADDRESS_ZIP]: ''});
              }
            }}
            error={formErrors[ADDRESS_ZIP]}
          />
        </div>
        <InputField
          inputFieldKey={NAME_FIELD}
          labelText="Cardholder Name"
          inputPlaceholder=""
          ariaLabel="Enter your name"
          isRequired
          value={formData[NAME_FIELD]}
          onChange={(value) => {
            setFormData({...formData, [NAME_FIELD]: value});
            if (formErrors[NAME_FIELD]) {
              setFormErrors({...formErrors, [NAME_FIELD]: ''});
            }
          }}
          error={formErrors[NAME_FIELD]}
        />
        <CreditCard
          error={formErrors[CARD_FIELD]}
          handleChange={(cardElementValue) => {
            if (cardElementValue?.error?.message) {
              setFormErrors({
                ...formErrors,
                [CARD_FIELD]: cardElementValue.error.message,
              });
            } else {
              setFormErrors({...formErrors, [CARD_FIELD]: ''});
            }
          }}
          setCardElement={setCardElement}
        />
        <div className="billing-form-container__form__terms-and-conditions">
          <h2>Terms and Conditions</h2>
          <p>
            By clicking “I Agree” below, you acknowledge that you are upgrading
            to our monthly Pro plan (“Subscription”) and agree to the following
            terms:
          </p>
          <ul>
            <li>
              You authorize us to charge your credit or debit card on a monthly
              basis for the amount specified at the time of purchase.
            </li>
            <li>
              You understand that your subscription will automatically renew
              each month unless cancelled by you or terminated by us.
            </li>
            <li>
              You shall keep payment information current at all times while the
              Subscription is active.
            </li>
            <li>
              You acknowledge that we may update and/or modify the subscription
              service and pricing at any time, and your continued use of Social
              Tables will be deemed as acceptance of and agreement to such
              modifications.
            </li>
            <li>
              If the method of payment cannot be verified, is invalid, is
              over-limit or is not otherwise acceptable, we may suspend your
              access to Social Tables or downgrade your Subscription to the base
              subscription level (free plan) without notice until payment in
              full is made.
            </li>
            <li>
              You understand that you may cancel your Subscription at any time,
              but cancellation will not be effective until the next billing
              cycle. No refunds will be given for partial months or unused
              portions of the service.
            </li>
            <li>
              Your use of Social Tables is subject to the applicable Terms of
              Use located at&nbsp;
              <ExternalLink type={PRODUCT_TERMS_OF_USE_LINK} />
            </li>
          </ul>
          <div className="billing-form-container__form__terms-and-conditions__checkbox">
            <label>
              <input
                type="checkbox"
                name="terms_and_conditions"
                onChange={(e) => setIsDisabled(!e.target.checked)}
              />
              I agree to the terms and conditions
            </label>
          </div>
        </div>
        <Button
          className="billing-form-container__form__button"
          text="Confirm plan purchase"
          type="submit"
          isPrimary
          isDisabled={!stripe || isDisabled}
        />
      </form>
    </div>
  );
};

export default BillingForm;
